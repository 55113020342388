import React, { FC, useEffect, useRef, useState } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import classNames from 'classnames';
import { isBrowser } from 'utils/browser';

import { IUmbracoImageComponent } from './models';
import './UmbracoImage.scss';

const UmbracoImage: FC<IUmbracoImageComponent> = ({ lazyLoad, className, image, alt }) => {
  const classes = classNames(className, 'dt-umbraco-image');
  const imageRef = useRef<HTMLImageElement>(null);
  const base64ImageRef = useRef<HTMLImageElement>(null);
  const [isVisible, setVisible] = useState<boolean>(!lazyLoad);
  const [display, setDisplay] = useState<string>('none');
  const [state, setState] = useState({
    mainImageOpacity: 0,
    base64ImageOpacity: 1,
  });
  const setImageReady = () => {
    setState({
      mainImageOpacity: 1,
      base64ImageOpacity: 0,
    });
    setDisplay('');
  };

  const handleIntersect = () => {
    if (
      isBrowser() &&
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver((entries) => {
        if (!isVisible && entries[0].isIntersecting) {
          setVisible(true);
        }
      });

      base64ImageRef && base64ImageRef.current && observer.observe(base64ImageRef.current);
    }
  };

  const onDomLoaded = () => {
    setDisplay('');
    // eslint-disable-next-line no-use-before-define
    removeSubscriptions();
  };
  setTimeout(onDomLoaded, 2200);

  function removeSubscriptions() {
    if (!isBrowser()) {
      return;
    }
    window.document.removeEventListener('mousemove', onDomLoaded);
    window.document.removeEventListener('touchstart', onDomLoaded);
    window.document.removeEventListener('keydown', onDomLoaded);
  }
  const handleDomLoaded = () => {
    if (!isBrowser()) {
      return;
    }
    window.document.addEventListener('mousemove', onDomLoaded);
    window.document.addEventListener('touchstart', onDomLoaded);
    window.document.addEventListener('keydown', onDomLoaded);
  };

  if (!image) {
    return null;
  }

  useEffect(() => {
    if (imageRef.current?.complete) {
      setImageReady();
    }
    handleIntersect();
    handleDomLoaded();

    return removeSubscriptions;
  }, []);

  return (
    <div className={classes}>
      {image.fluid?.base64 && (
        <img
          ref={base64ImageRef}
          alt="none"
          className="dt-umbraco-image__img"
          src={image.fluid.base64}
          style={{
            transitionDelay: '200ms',
            opacity: state.base64ImageOpacity,
          }}
        />
      )}
      {isVisible ? (
        <picture>
          <source srcSet={image.fluid?.srcSet} type="image/webp" />
          <source srcSet={image.fallbackUrl} type="image/jpeg" />
          <img
            ref={imageRef}
            alt={alt}
            style={{
              transition: 'opacity 200ms ease 0s',
              opacity: state.mainImageOpacity,
              display,
            }}
            onLoad={setImageReady}
            data-srcset={image.fluid?.srcSet}
            data-src={image.fallbackUrl}
            data-sizes="auto"
            loading={lazyLoad ? 'lazy' : undefined}
          />
        </picture>
      ) : null}
    </div>
  );
};

UmbracoImage.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  lazyLoad: true,
};

export default UmbracoImage;
