export type SwipeElement = HTMLElement | null;

const SWIPE_SENSIVITY_PIXELS = 9;

interface ISwipeService {
  onLeft: (callback: Function) => SwipeService;
  onRight: (callback: Function) => SwipeService;
  onUp: (callback: Function) => SwipeService;
  onDown: (callback: Function) => SwipeService;
  destroy: () => void;
}

class SwipeService implements ISwipeService {
  private xDown: null | number;

  private yDown: null | number;

  private xDiff: number;

  private yDiff: number;

  private element: SwipeElement;

  constructor(element: SwipeElement) {
    this.xDown = null;
    this.yDown = null;
    this.xDiff = 0;
    this.yDiff = 0;
    this.element = this.init(element);
  }

  public onLeft = (callback) => {
    this.onLeft = callback;

    return this;
  };

  public onRight = (callback) => {
    this.onRight = callback;

    return this;
  };

  public onUp = (callback) => {
    this.onUp = callback;

    return this;
  };

  public onDown = (callback) => {
    this.onDown = callback;

    return this;
  };

  public destroy = (): void => {
    if (this.element) {
      this.element.removeEventListener('touchmove', this.onTouchMove);
      this.element.removeEventListener('touchstart', this.onTouchStart);
    }
  };

  private init = (element: SwipeElement): SwipeElement => {
    if (element) {
      element.addEventListener('touchstart', this.onTouchStart, false);
      element.addEventListener('touchmove', this.onTouchMove, false);

      return element;
    }

    return null;
  };

  private onTouchStart = (evt: TouchEvent): void => {
    this.xDown = evt.touches[0].clientX;
    this.yDown = evt.touches[0].clientY;
  };

  private onTouchMove = (evt: TouchEvent): void => {
    this.handleTouchMove(evt);
  };

  private invokeCallback = (callback: Function): void => {
    this.xDown = null;
    this.yDown = null;

    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  private handleTouchMove = (evt: TouchEvent): void => {
    evt.stopPropagation();
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    this.xDiff = this.xDown - xUp;
    this.yDiff = this.yDown - yUp;

    if (Math.abs(this.xDiff) > Math.abs(this.yDiff)) {
      if (this.xDiff > SWIPE_SENSIVITY_PIXELS) {
        return this.invokeCallback(this.onLeft);
      }
      if (this.xDiff < -SWIPE_SENSIVITY_PIXELS) {
        this.invokeCallback(this.onRight);
      }
    } else {
      if (this.yDiff > SWIPE_SENSIVITY_PIXELS) {
        return this.invokeCallback(this.onUp);
      }
      if (this.yDiff < -SWIPE_SENSIVITY_PIXELS) {
        this.invokeCallback(this.onDown);
      }
    }

    this.xDown = null;
    this.yDown = null;
  };
}

export default SwipeService;
