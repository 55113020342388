const DEFER_TIMEOUT = 1000;
const USER_INTERACTION_EVENTS = ['scroll', 'mousemove', 'focus', 'pointerdown'];

const getDeferFunctionImplementation = (timeout: number) => {
  const isRequestIdleCallbackAvailable = 'requestIdleCallback' in window;

  const deferFunction: Function = isRequestIdleCallbackAvailable
    ? window.requestIdleCallback
    : setTimeout;
  const deferParams = isRequestIdleCallbackAvailable ? { timeout } : timeout;

  return (callback: () => void) => deferFunction(callback, deferParams);
};

export default function runOnUserInteraction(callback: () => void, timeout = DEFER_TIMEOUT) {
  const deferFunction = getDeferFunctionImplementation(timeout);

  const eventHandler = () => {
    deferFunction(callback);

    USER_INTERACTION_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler, true);
    });
  };

  USER_INTERACTION_EVENTS.forEach((eventName) => {
    window.addEventListener(eventName, eventHandler, true);
  });
}
