import {
  IArticleSchema,
  IOrganizationSchema,
  IProductSchema,
  ISchemaData,
  ISchemaDataExtractor,
  ISchemaScriptData,
  IWebpageSchema,
  Schemas,
  SchemaTypes,
} from './model';

export class SchemaService {
  private schemaTypes = {
    organization: 'Organization',
    product: 'Product',
    article: 'Article',
    webpage: 'WebPage',
  } as const;

  private entityTypes = {
    imageObject: 'ImageObject',
    brand: 'Brand',
    organization: 'Organization',
    webPage: 'WebPage',
    person: 'Person',
  } as const;

  private schemasMap = {
    [this.schemaTypes.webpage]: this.getWebpageSchema.bind(this),
    [this.schemaTypes.article]: this.getArticleSchema.bind(this),
    [this.schemaTypes.product]: this.getProductSchema.bind(this),
    [this.schemaTypes.organization]: this.getOrganizationSchema.bind(this),
  };

  getSchemas(schemas: SchemaTypes[], schemaData: ISchemaData): ISchemaScriptData[] {
    return schemas
      .filter((type) => Object.values(this.schemaTypes).includes(type))
      .map((type) => this.getSchemaScriptData(this.schemasMap[type](schemaData)));
  }

  extractSchemaData({
    seo,
    pagePathname,
    siteSettings,
    brandSettings,
    lang,
    schemaImageUrl,
  }: ISchemaDataExtractor): ISchemaData {
    const { siteUrl = '', siteName } = siteSettings || {};
    const { brandName, brandLogo, youtubeUrl, facebookUrl, brandLogoSchema } = brandSettings || {};
    const origin = siteUrl.endsWith('/') ? siteUrl.slice(0, -1) : siteUrl;

    return {
      lang,
      siteUrl: origin,
      siteName,
      pageHref: `${origin}${pagePathname !== '/' ? pagePathname : ''}`,
      brandName,
      seoTitle: seo?.seoMetaTitle || '',
      seoDescription: seo?.seoMetaDescription || '',
      logoUrl: brandLogoSchema || brandLogo?.fallbackUrl,
      schemaImageUrl: schemaImageUrl || '',
      sameAs: [...(youtubeUrl ? [youtubeUrl] : []), ...(facebookUrl ? [facebookUrl] : [])],
    };
  }

  getSchemaScriptData(scriptData: Schemas): ISchemaScriptData {
    return {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(scriptData),
    };
  }

  getOrganizationSchema({
    brandName,
    pageHref,
    seoDescription,
    logoUrl,
  }: ISchemaData): IOrganizationSchema {
    return {
      '@context': 'https://schema.org',
      '@type': this.schemaTypes.organization,
      name: brandName,
      url: pageHref,
      description: seoDescription,
      logo: {
        '@type': this.entityTypes.imageObject,
        url: logoUrl,
      },
    };
  }

  getProductSchema({
    seoTitle,
    seoDescription,
    schemaImageUrl,
    brandName,
  }: ISchemaData): IProductSchema {
    return {
      '@context': 'https://schema.org',
      '@type': this.schemaTypes.product,
      name: seoTitle,
      description: seoDescription,
      image: schemaImageUrl || '',
      brand: {
        '@type': this.entityTypes.brand,
        name: brandName,
      },
    };
  }

  getArticleSchema({
    seoTitle,
    seoDescription,
    brandName,
    schemaImageUrl,
  }: ISchemaData): IArticleSchema {
    return {
      '@context': 'https://schema.org',
      '@type': this.schemaTypes.article,
      description: seoDescription,
      headline: seoTitle,
      author: {
        '@type': this.entityTypes.organization,
        name: brandName,
      },
      publisher: {
        '@type': this.entityTypes.organization,
        name: brandName,
      },
      image: {
        '@type': this.entityTypes.imageObject,
        url: schemaImageUrl || '',
      },
    };
  }

  getWebpageSchema({
    siteUrl,
    siteName,
    pageHref,
    brandName,
    lang,
    sameAs,
    logoUrl,
  }: ISchemaData): IWebpageSchema {
    return {
      '@context': 'https://schema.org',
      '@type': this.schemaTypes.webpage,
      name: siteName,
      mainEntityOfPage: {
        '@type': this.entityTypes.webPage,
        '@id': pageHref,
      },
      inLanguage: lang,
      author: {
        '@type': this.entityTypes.person,
        name: brandName,
      },
      publisher: {
        '@type': this.entityTypes.organization,
        name: brandName,
        url: siteUrl,
        sameAs,
        logo: {
          '@type': this.entityTypes.imageObject,
          url: logoUrl,
        },
      },
    };
  }
}

export const schemaService = new SchemaService();
