import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IFooterLinks } from './models';

const FooterLinks: FC<IFooterLinks> = ({ links }) => (
  <>
    {links.map(({ name, url }) => (
      <li key={name}>
        <Link to={url}>{name}</Link>
      </li>
    ))}
  </>
);

export default FooterLinks;
