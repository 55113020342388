import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { useLocation } from '@reach/router';

import { preloadFonts } from './preload';
import { ISeo } from './models';
import { OG_TYPES } from './constants';

import './Seo.scss';

const Seo: FC<ISeo> = ({
  siteSettings,
  brandSettings,
  description,
  lang,
  keywords,
  seoNoIndex,
  title,
  canonicalUrl,
  disableCanonicals = false,
  externalHreflangs,
  url,
  ogImage,
  ogPageType,
}) => {
  const location = useLocation();
  const {
    isRTL,
    siteUrl,
    lang: settingsLang,
    siteName: settingsSiteName,
    siteLang: settingsSiteLang,
  } = siteSettings;
  const { brandName, defaultOgImage } = brandSettings;

  const siteName = settingsSiteName || '';
  const titleTemplate = title === siteName ? '%s' : `%s | ${siteName}`;
  const siteLang = lang || settingsSiteLang || settingsLang;
  const direction = isRTL === '1' ? 'rtl' : 'ltr';
  const origin = siteUrl && siteUrl.endsWith('/') ? siteUrl.slice(0, -1) : siteUrl;
  const ogUrl = origin ? `${origin}${url}` : location?.origin + location?.pathname;

  let canonicalGeneratedUrl = siteUrl || '';
  if (siteUrl) {
    canonicalGeneratedUrl = `${
      siteUrl[siteUrl.length - 1] === '/' ? siteUrl.substring(0, siteUrl.length - 1) : siteUrl
    }${url}`;
  }

  const meta = [
    {
      name: 'robots',
      content:
        process.env.GATSBY_ACTIVE_ENV === 'production' && !seoNoIndex
          ? 'index, follow'
          : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: ogPageType || OG_TYPES.WEBSITE,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: brandName,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      property: 'og:url',
      content: ogUrl,
    },
    ...(ogImage || defaultOgImage
      ? [
          {
            property: 'og:image',
            content: ogImage || defaultOgImage,
          },
        ]
      : []),
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang, dir: direction }} {...{ title, titleTemplate, meta }}>
      {process.env.GATSBY_ACCESSIBILITY_KEY ? (
        <script
          type="text/javascript"
          data-accesskey={process.env.GATSBY_ACCESSIBILITY_KEY}
          src={withPrefix(`AccessibilityScript.js`)}
        />
      ) : null}
      {!disableCanonicals ? (
        <link rel="canonical" href={canonicalUrl || canonicalGeneratedUrl} />
      ) : null}
      {externalHreflangs?.map((link) => {
        return <link rel="alternate" href={link.value} hrefLang={link.key} />;
      })}
      {preloadFonts.map((font) => (
        <link rel="preload" as="font" key={font.href} crossOrigin="anonymous" {...font} />
      ))}
    </Helmet>
  );
};

export default Seo;
