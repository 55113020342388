import React, { FC, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import { setStorageData, getStorageData } from 'utils/storage';
import { IAlertCustom } from './models';
import './AlertCustom.scss';

const AlertCustom: FC<IAlertCustom> = ({
  type,
  title,
  text,
  closeMenu,
  name = 'DEFAULT_ALERT',
  isFixed,
}) => {
  const [isVisible, setVisible] = useState<boolean>(false);

  const alertClasses = classNames('dt-alert-custom', {
    'dt-alert-custom--fixed': isFixed,
  });

  const alertTypeClasses = classNames('dt-alert-custom__icon', {
    [`dt-alert-custom__icon--${type}`]: type,
  });

  const handleCloseAlert = useCallback(() => {
    setVisible(false);
    setStorageData(name, 'isClosed');
  }, []);

  useEffect(() => {
    const isAlertCustomClosed = getStorageData(name);
    setVisible(!isAlertCustomClosed);
  }, []);

  return isVisible ? (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={alertClasses} onClick={closeMenu}>
        <button
          onClick={handleCloseAlert}
          className="dt-alert-custom__btn"
          type="button"
          aria-label="Close"
        >
          <IconCustom icon="close_X_icon" />
        </button>
        <strong className="dt-alert-custom__title">
          {type ? (
            <i className={alertTypeClasses}>
              <IconCustom icon="Warning_icon" />
            </i>
          ) : null}
          {title}
        </strong>
        {text ? <p>{text}</p> : null}
      </div>
    </>
  ) : null;
};

export default AlertCustom;
