import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import loadable from '@loadable/component';

import { isBrowser } from 'utils/browser';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MobileAppPromoBanner from 'components/MobileAppPromoBanner';
import SubMenu from 'components/common/SubMenu';
import Seo from 'components/common/Seo';
import StructuredPageSchema from 'components/common/StructuredPageSchema';
import BazaarVoice from 'components/BazaarVoice';
import WhatsAppWidget from 'components/WhatsAppWidget';
import { schemaService } from 'utils/schemaService';

import { ILayout, TStaticProps } from './models.d';

import './Layout.scss';

const COVID_ALERT = {
  type: 'danger',
  title: 'Covid Alert',
  text: 'Discovert the facts not the myths, at our dedicated COVID-19 resource',
};

const Layout: FC<ILayout> = ({
  isShowAlert,
  seo,
  children,
  headerTransparent,
  className,
  subMenu,
  siteSettings,
  homepageSettings,
  menu,
  footerNavigation,
  mobileAppPromoBanner,
  languageSelector,
  url,
  additionalSchema,
  schemaImageUrl,
  createDate,
  updateDate,
  size,
}) => {
  const classes = classNames('dt-layout', {
    'dt-layout--transparent': headerTransparent,
    'dt-layout--sub-menu': subMenu,
    [`${className}`]: className,
  });

  const lang = siteSettings?.lang;

  const { regionDropDown, brandSettings, newsletterSignUp }: TStaticProps = useStaticQuery(graphql`
    {
      regionDropDown {
        id
        selectedCountryCode
        section {
          properties {
            title
            countries {
              properties {
                code
                name
                linkURL {
                  url
                  name
                }
              }
            }
          }
        }
      }
      brandSettings {
        brandName
        brandLogo {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        brandLogoSchema
        youtubeUrl
        facebookUrl
        schemaLogoFileName
        defaultOgImage
        contactPoint {
          properties {
            telephone
            contactType
            areaServed
            availableLanguage
            streetAddress
            addressRegion
            postalCode
            addressCountry
            foundingDate
          }
        }
      }
      newsletterSignUp {
        wayinFormSrc
        wayinFormId
      }
    }
  `);

  const subMenuNav = subMenu?.links?.length ? (
    <SubMenu links={subMenu?.links} ariaLabel={subMenu?.ariaLabel} />
  ) : null;

  const handleSkipToContent = () => {
    const main = isBrowser() && document.getElementById('dt-main');
    main && main.focus();
  };

  const lsSettings = {
    ...languageSelector,
    currentLangCode: lang,
  } as PageContent.ILanguageSwitcher;

  const renderNewsletter = useMemo(() => {
    if (newsletterSignUp) {
      const { wayinFormSrc, wayinFormId } = newsletterSignUp;
      if (wayinFormSrc?.length && wayinFormId?.length) {
        const NewsletterPopup = loadable(() => import('components/NewsletterPopup'));

        return <NewsletterPopup {...newsletterSignUp} />;
      }
    }

    return null;
  }, [newsletterSignUp]);

  const schemaData = schemaService.extractSchemaData({
    seo,
    pagePathname: url,
    siteSettings,
    brandSettings,
    lang,
    schemaImageUrl,
    createDate,
    updateDate,
    size,
  });

  return (
    <BazaarVoice url={siteSettings?.useBV}>
      <div className={classes}>
        <Seo
          siteSettings={siteSettings}
          brandSettings={brandSettings}
          seoNoIndex={seo.seoNoIndex}
          title={seo.seoMetaTitle}
          description={seo.seoMetaDescription}
          keywords={seo.seoMetaKeywords}
          canonicalUrl={seo.seoCanonicalUrl}
          ogImage={seo.seoImage}
          externalHreflangs={seo.seoExternalHreflangs}
          url={url}
          disableCanonicals={seo.disableCanonicals}
          ogPageType={seo.ogPageType}
        />
        <StructuredPageSchema additionalSchema={additionalSchema} schemaData={schemaData} />
        <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />
        {siteSettings?.buttonSkip ? (
          <a
            className="dt-skip-main"
            href="#dt-main"
            onClick={handleSkipToContent}
            aria-label={siteSettings?.buttonSkip || 'Skip'}
          >
            {siteSettings?.buttonSkip}
          </a>
        ) : null}
        <Header
          languageSelector={lsSettings}
          brandSettings={brandSettings}
          menu={menu}
          headerTransparent={headerTransparent}
          alert={isShowAlert ? COVID_ALERT : null}
          currentPage={className}
          customFields={{
            previousSearches: siteSettings?.previousSearches,
            placeholderSearch: siteSettings?.placeholderSearch,
            viewAllResults: siteSettings?.viewAllResults,
            swipeUpToClose: siteSettings?.searchSwipeUpToCloseText,
          }}
          buttonSearch={siteSettings?.buttonSearch || 'Search'}
        />
        {subMenuNav}
        <main id="dt-main" className="dt-layout__main">
          {children}
        </main>
        <WhatsAppWidget langProp={lang} />
        {mobileAppPromoBanner ? (
          <Container fluid>
            <MobileAppPromoBanner {...{ ...mobileAppPromoBanner }} />
          </Container>
        ) : null}
        <Footer
          regionDropDown={regionDropDown}
          footerNavigation={footerNavigation}
          brandSettings={brandSettings}
          newsletterBanner={homepageSettings?.newsletterBanner}
          siteSettings={siteSettings}
        />
        {renderNewsletter}
      </div>
    </BazaarVoice>
  );
};

export default Layout;

export const queryDefaultProps = graphql`
  fragment FragmentLanguageSwitcher on LANGUAGE_SELECTOR {
    title
    show
    redirectBehavior
    languages {
      properties {
        title
        langCode
        urlPrefix
        ariaLabel
      }
    }
  }
  fragment FragmentSiteSettings on SiteSettings {
    closeButtonAriaLabel
    siteUrl
    siteName
    lang
    newsletterSignUp
    enableSignupButtonInProductPage
    hideSortButton
    discountButton
    titleRegions
    previousSearches
    placeholderSearch
    viewAllResults
    buttonSkip
    isRTL
    useBV
    searchSwipeUpToCloseText
    disclaimer
    siteLang
    relatedProductsRegularTitle
    relatedProductsBoldTitle
    buttonSearch
    seoPageExcluding {
      name
      target
      url
    }
  }
  fragment FragmentHomepageSettings on HOMEPAGE_SETTINGS {
    newsletterBanner {
      buttonText
      footerButtonTitle
      buttonURL {
        url
      }
      titleText
      regularTitle
    }
  }
  fragment FragmentMobileAppPromoBanner on MOBILE_APP_PROMO_BANNER {
    appLogo {
      properties {
        image {
          url
        }
        link {
          name
          target
          url
        }
      }
    }
    appstoreLinks {
      properties {
        image {
          url
        }
        link {
          name
          target
          url
        }
      }
    }
    ingress
  }
  fragment FragmentHeader on Menu {
    navigation {
      properties {
        label
        url {
          url
        }
        panels {
          structure
          properties {
            name
            subCategories {
              id
              name
              title
              link {
                url
                queryString
              }
              image {
                fallbackUrl
                fluid {
                  srcSet
                  base64
                }
              }
            }
            products {
              properties {
                image {
                  fallbackUrl
                  fluid {
                    srcSet
                    base64
                  }
                }
                name
                link {
                  url
                  queryString
                }
              }
            }
          }
        }
      }
    }
    toggleBtnAriaLabel
    navigationAriaLabel
  }
  fragment FragmentFooter on FOOTER_NAVIGATION {
    footerMiddle {
      links {
        url
        icon
        name
      }
      title
    }
    compositionContainer {
      properties {
        groupLink {
          url
          name
        }
        links {
          url
          name
        }
        name
      }
    }
    footerBottom {
      link {
        url
        name
      }
      copyright
    }
  }
`;
