import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import DropdownRegion from 'gatsby-theme-dettol/src/components/DropdownRegion';
import {IFooterTopRegionsComponent} from 'gatsby-theme-dettol/src/components/Footer/FooterTop/FooterTopRegions/models';

const FooterTopRegions: FC<IFooterTopRegionsComponent> = ({
        regionDropDown: { section, selectedCountryCode },
        titleRegions
    }) => (
        <Col className="dt-footer__top-col" sm={12} lg={8}>
            <strong className="dt-footer__top-title">{titleRegions}</strong>

            <DropdownRegion data={section} selectedCountryCode={selectedCountryCode} />
        </Col>
    );

export default FooterTopRegions;