import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Logo from 'components/common/Logo';
import FooterTopCategriesCols from './FooterTopCategriesCols';
import FooterTopRegions from './FooterTopRegions';
import Disclaimer from './Disclaimer';
import { IFooterTopComponent } from './models';
import './FooterTop.scss';

const FooterTop: FC<IFooterTopComponent> = ({
  brandSettings,
  footerNavigation,
  regionDropDown,
  titleRegions,
  disclaimer,
}) => (
  <Container fluid className="dt-footer__top">
    <div className="dt-footer__top-holder">
      <Logo brandSettings={brandSettings} />

      <div className="dt-footer__top-row">
        <Row>
          <FooterTopCategriesCols footerNavigation={footerNavigation} />

          <FooterTopRegions regionDropDown={regionDropDown} titleRegions={titleRegions} />
        </Row>
      </div>
    </div>
    {disclaimer ? <Disclaimer disclaimer={disclaimer} /> : null}
  </Container>
);

export default FooterTop;
