import React, { FC } from 'react';
import { Link } from 'gatsby';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';

import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { isBrowser } from 'utils/browser';
import { IFooterTopCategoriesComponent } from './models';

export const NavWraper: FC<{ links: PageContent.TLink[] }> = ({ links }) => {
  if (!isBrowser() || window.innerWidth < 768) {
    return null;
  }
  const FooterLinks = loadable(() => import('components/Footer/FooterLinks'));

  return (
    <nav className="dt-footer__top-nav">
      <ul>
        <FooterLinks links={links} />
      </ul>
    </nav>
  );
};

const FooterTopCategriesCols: FC<IFooterTopCategoriesComponent> = ({ footerNavigation }) => {
  return (
    <>
      {footerNavigation?.compositionContainer.map(({ properties }) => (
        <Col className="dt-footer__top-col" sm={6} lg={4} key={properties.name}>
          <strong className="dt-footer__top-title">
            <Link to={extractUrlFromMultiPicker(properties.groupLink)}>{properties.name}</Link>
          </strong>

          <NavWraper links={properties.links} />
        </Col>
      ))}
    </>
  );
};

export default FooterTopCategriesCols;
