import React, { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { IDropdownRegionItem } from './models';
import './DropdownRegionItem.scss';

const DropdownRegionItem: FC<IDropdownRegionItem> = ({
  code,
  url,
  name,
  isLast,
  toggleDropdown,
}) => (
  <li className="dt-dropdown-region__item">
    <a
      target="_blank"
      rel="noreferrer"
      href={url}
      className="dt-dropdown-region__item-link"
      onBlur={() => {
        if (isLast) {
          toggleDropdown(false);
        }
      }}
    >
      <span className="dt-dropdown-region__item-img-holder">
        <ReactCountryFlag
          className="dt-dropdown-region__item-img lazyload"
          countryCode={code}
          svg
          loading="lazy"
          aria-label={name}
          alt={name}
        />
      </span>
      {name}
    </a>
  </li>
);

export default DropdownRegionItem;
