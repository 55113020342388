import React, { FC, useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { IDropdownLanguageItem } from './models';
import './DropdownLanguageItem.scss';

const DropdownLanguageItem: FC<IDropdownLanguageItem> = ({
  title,
  onClick,
  urlPrefix,
  ariaLabel,
}) => {
  const navigateTo = useCallback(() => onClick(urlPrefix), [urlPrefix]);

  return (
    <Dropdown.Item
      onClick={navigateTo}
      aria-label={ariaLabel}
      className="dt-dropdown-language-item"
    >
      {title}
    </Dropdown.Item>
  );
};

export default DropdownLanguageItem;
