import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useId } from 'react-id-generator';
import { navigate } from 'gatsby';
import { globalHistory as history } from '@reach/router';
import { parseBoolean } from 'utils/parseHelpers';

import IconCustom from 'components/common/IconCustom';
import DropdownLanguageItem from './DropdownLanguageItem';
import './DropdownLanguage.scss';

import { IDropdownLanguage } from './models';

const DropdownLanguage: FC<IDropdownLanguage> = ({
  redirectToHome,
  title,
  langs,
  currentLangCode,
}) => {
  const [dropId] = useId();
  const { location } = history;
  const currentLang = langs?.find((lang) => lang.properties.langCode === currentLangCode);
  const { urlPrefix: currentPrefix, title: currentLangName } = currentLang?.properties || {
    urlPrefix: '',
    title: '',
  };

  const getLang = (allLangs, lang) =>
    allLangs
      ? allLangs.find(({ properties: { urlPrefix } }) => urlPrefix === lang).properties.langCode
      : '';

  const navigateTo = (value) => {
    const lang = getLang(langs, value);

    if (lang !== currentLang?.properties?.langCode) {
      (window as any).OneTrust?.changeLanguage(lang);
    }
    const pathname = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;
    const newPath = parseBoolean(redirectToHome) ? value : pathname.replace(currentPrefix, value);
    navigate(newPath);
  };

  return (
    <div className="dt-dropdown-language">
      <strong className="dt-dropdown-language__title">{title}</strong>
      <Dropdown
        className="dt-dropdown-language__drop"
        id={`dt-dropdown-language dt-dropdown-language-${dropId}`}
      >
        <Dropdown.Toggle variant="outline-primary">
          <span className="dt-dropdown-language__opener">{currentLangName}</span>
          <IconCustom icon="next_arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {langs?.length
            ? langs.map(({ properties }) => (
                <DropdownLanguageItem
                  key={`${properties?.langName}_${properties?.urlPrefix}`}
                  {...properties}
                  ariaLabel={properties?.ariaLabel}
                  onClick={navigateTo}
                />
              ))
            : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownLanguage;
