import { isBrowser } from 'utils/browser';
import runOnUserInteraction from 'utils/runOnUserInteraction';
import emitEvent from 'utils/event';
import { BV_READY_EVENT } from 'hooks/useBVDynamicCatalog/constants';

const BV_TIMEOUT = 1200;
// common buffer for checking BV loading, thanks JS for single thread architecture.
let bvPromise: Promise<BazaarVoice | undefined> | null = null;
// only this function have to be used for getting BV
export const getBV = (url: string) => {
  if (bvPromise) return bvPromise;
  bvPromise = new Promise((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      resolve(window.$BV);
      emitEvent(BV_READY_EVENT);
    };

    document.getElementsByTagName('head')[0].appendChild(script);
  });

  return bvPromise;
};
export const deferLoadBV = (url?: string) => {
  if (!url || !isBrowser()) return;

  runOnUserInteraction(() => {
    getBV(url);
  }, BV_TIMEOUT);
};
