import React, { FC } from 'react';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import './MobileAppPromoBanner.scss';

const getRel = (link: PageContent.TLinkSimple): string | undefined =>
  link?.target === '_blank' ? 'noreferrer' : undefined;

const MobileAppPromoBanner: FC<PageContent.MobileAppPromoBanner> = ({
  appLogo: [appLogoItem],
  ingress,
  appstoreLinks,
}) => {
  const {
    properties: { link: appLogoLink, image: appLogoImage },
  } = appLogoItem;

  return (
    <div className="dt-mobile-app-promo-banner__wrapper">
      <a
        href={extractUrlFromMultiPicker(appLogoLink)}
        target={appLogoLink[0].target}
        rel={getRel(appLogoLink[0])}
      >
        <img
          className="dt-mobile-app-promo-banner__logo"
          src={appLogoImage.url}
          alt={appLogoLink[0].name}
        />
      </a>
      <p className="dt-mobile-app-promo-banner__ingress">{ingress}</p>
      <div className="dt-mobile-app-promo-banner__links">
        {appstoreLinks.map(({ properties: { link, image } }) => {
          const url = extractUrlFromMultiPicker(link);

          return (
            <a key={url} href={url} target={link[0].target} rel={getRel(link[0])}>
              <img
                className="dt-mobile-app-promo-banner__appstore-logo"
                src={image.url}
                alt={link[0].name}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAppPromoBanner;
