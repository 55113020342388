import React, { FC } from 'react';

import { IIconCustom } from './models';
import './IconCustom.scss';

const IconCustom: FC<IIconCustom> = ({ icon, style }) => (
  <span className={`dt-icon-custom icon-${icon}`} style={style} />
);

export default IconCustom;
