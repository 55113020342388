import { TMultiPickerUrl } from 'components/Header/HeaderNav/models';

export const extractUrlFromMultiPicker = (urlObject: TMultiPickerUrl): string =>
  urlObject && urlObject[0] && urlObject[0].url
    ? `${urlObject[0].url}${urlObject[0].queryString || ''}`
    : '/';

export const getHomePageUrlPrefixes = (
  languages: Pick<PageContent.ILanguageSwitcher, 'languages'>
): string[] => {
  if (!Array.isArray(languages)) {
    return ['/'];
  }

  return languages.map((lang) => lang.properties?.urlPrefix);
};

export default extractUrlFromMultiPicker;
