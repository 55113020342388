import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FooterBottomNav from './FooterBottomNav';
import './FooterBottom.scss';

type TFooterBottomComponentProps = {
  footerNavigation: PageContent.TFooterNavigation;
};

const FooterBottom: FC<TFooterBottomComponentProps> = ({ footerNavigation }) => {
  return (
    <div className="dt-footer__bottom">
      <Container fluid>
        <Row>
          {footerNavigation?.footerBottom?.copyright ? (
            <Col xs={{ order: 1, span: 12 }} md={{ order: 0, span: 6 }} lg={5}>
              <p className="dt-footer__bottom-text">
                {`© ${new Date().getFullYear()} ${footerNavigation.footerBottom.copyright}`}
              </p>
            </Col>
          ) : null}
          {footerNavigation?.footerBottom?.link ? (
            <Col xs={{ order: 0, span: 12 }} md={{ order: 1, span: 6 }} lg={7}>
              <FooterBottomNav links={footerNavigation.footerBottom.link} />
            </Col>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};

export default FooterBottom;
