const OG_TYPES = {
  ARTICLE: 'article',
  PRODUCT: 'product',
  WEBSITE: 'website',
};

const OG_PAGE_TYPES = {
  ARTICLE: OG_TYPES.ARTICLE,
  PRODUCT: OG_TYPES.PRODUCT,
};

export { OG_TYPES, OG_PAGE_TYPES };
