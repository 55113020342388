import React, { FC, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import IconCustom from 'components/common/IconCustom';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { isBrowser, prepareClassName } from 'utils/browser';
import { sortSubCategories } from './utils';
import {
  ICategoryWrapperProps,
  IItemColumnProps,
  PanelsBlockName,
  IHeaderNavDropdownMenuProps,
} from './models.d';

import './HeaderNavDropdownMenu.scss';

const defaultMenuWidth = 992;

const ProductCategoryWrapper: FC<ICategoryWrapperProps> = ({ name, centered, children }) => {
  const [windowWidth, setWindowWidth] = useState(defaultMenuWidth);
  const [isToggle, setToggle] = useState(false);

  const menuTitleMobileClasses = classNames('dt-header__dropdown-menu-title', {
    'dt-header__dropdown-menu-title--active': isToggle,
  });
  const menuTitleLaptopClasses = classNames('dt-header__dropdown-menu-title', {
    'dt-header__dropdown-menu-title--centered': centered,
  });

  useEffect(() => {
    if (isBrowser()) {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  const dropdownContent =
    windowWidth >= defaultMenuWidth ? (
      <>
        <strong className={menuTitleLaptopClasses}>{name}</strong>
        <div className="dt-header__dropdown-menu-items">{children}</div>
      </>
    ) : (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <strong className={menuTitleMobileClasses} onClick={handleToggle}>
          {name}
          <span className="dt-header__dropdown-menu-caret">
            <IconCustom icon="next_arrow" />
          </span>
        </strong>
        <Collapse in={isToggle}>
          <div className="dt-header__dropdown-menu-items">{children}</div>
        </Collapse>
      </>
    );

  return <div className="dt-header__dropdown-menu-col">{dropdownContent}</div>;
};

const ItemColumn: FC<IItemColumnProps> = ({ name, link, image }) => (
  <div className="dt-header__dropdown-menu-item">
    <Link className={`dt-header__dropdown-menu-item-holder ${prepareClassName(name)}`} to={link}>
      <UmbracoImage image={image} alt={name} />
      <span>{name}</span>
    </Link>
  </div>
);

const HeaderNavDropdownMenu: FC<IHeaderNavDropdownMenuProps> = ({ panels }) => {
  const centered = panels.length === 1;
  const menuRowClassName = classNames('dt-header__dropdown-menu-row', {
    'dt-header__dropdown-menu-row--centered': centered,
  });

  return (
    <div className="dt-header__dropdown-menu">
      <div className="dt-header__dropdown-menu-holder">
        <div className="dt-header__dropdown-menu-frame">
          <div className={menuRowClassName}>
            {panels.map((panel) => {
              switch (panel.structure) {
                case PanelsBlockName.SUB_CATEGORIES:
                  return (
                    <ProductCategoryWrapper
                      key={`${panel.structure}-${panel.properties.name}}`}
                      centered={centered}
                      name={panel.properties.name}
                    >
                      {sortSubCategories(panel.properties.subCategories).map((subCategory) => (
                        <ItemColumn
                          key={`category-wrapper-${panel.structure}-${subCategory.name}`}
                          name={subCategory.title || subCategory.name}
                          image={subCategory.image}
                          link={extractUrlFromMultiPicker(subCategory.link)}
                        />
                      ))}
                    </ProductCategoryWrapper>
                  );

                case PanelsBlockName.PRODUCTS:
                  return (
                    <ProductCategoryWrapper
                      key={`${panel.structure}-${panel.properties.name}}`}
                      centered={centered}
                      name={panel.properties.name}
                    >
                      {panel.properties.products?.map(({ properties }) => (
                        <ItemColumn
                          key={`category-wrapper-${panel.structure}-${properties.name}`}
                          name={properties.name}
                          image={properties.image}
                          link={extractUrlFromMultiPicker(properties.link)}
                        />
                      ))}
                    </ProductCategoryWrapper>
                  );

                default:
                  return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavDropdownMenu;
