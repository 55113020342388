import React, { FC } from 'react';
import IconCustom from 'components/common/IconCustom';
import { ISocialNetworksLinks } from './models';

const SocialNetworksLinks: FC<ISocialNetworksLinks> = ({ links }) => (
  <>
    {links.map(({ name, url, icon }) => (
      <li key={name}>
        <a href={url} target="_blank" rel="noreferrer noopener" aria-label={name}>
          <span className="accessibility-hidden">{name}</span>
          <IconCustom icon={icon} />
        </a>
      </li>
    ))}
  </>
);

export default SocialNetworksLinks;
