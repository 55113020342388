import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useId } from 'react-id-generator';
import ReactCountryFlag from 'react-country-flag';

import getDropDownItem from 'utils/dropDownControl';
import { isBrowser } from 'utils/browser';

import IconCustom from 'components/common/IconCustom';
import DropdownRegionGroup from './DropdownRegionGroup';

import { IDropdownRegion, TRegion, TFormattedRegion, TRegionGroup } from './models';

import './DropdownRegion.scss';

const regionsOrder = {
  africa: 1,
  asia: 2,
  europe: 3,
  australia: 4,
  'south-america': 5,
};

const formatRegion = (region: TRegion): TFormattedRegion => {
  const { title, region: regionModificator, countries } = region;

  return {
    title,
    region: regionModificator?.[0] || title.toLowerCase().replace(/\s/g, '-'),
    countries: countries.map((c) => c.properties),
  };
};
const mapLast = <T1, T2>(arr: T1[], func: (arg: T1) => T2) =>
  arr.map((el, i) => (i === arr.length - 1 ? func(el) : el));
const formatRegions = (data: TRegionGroup[]) => {
  const formatted = data.map((group) => formatRegion(group.properties));
  const sorted = formatted.sort((a, b) => regionsOrder[a.region] - regionsOrder[b.region]);

  return mapLast(sorted, (region) => ({
    ...region,
    countries: mapLast(region.countries, (country) => ({ ...country, isLast: true })),
  }));
};

const DropdownRegion: FC<IDropdownRegion> = ({ data, selectedCountryCode }) => {
  const [dropId] = useId();
  const [regions, setRegions] = useState<TFormattedRegion[]>(() => formatRegions(data));

  useEffect(() => {
    setRegions(formatRegions(data));
  }, [data]);

  const selectedCountry = regions
    .map((region) => region.countries)
    .reduce((flat, toFlatten) => flat.concat(toFlatten), [])
    .find(
      ({ code }) =>
        String(code).toLowerCase() === String(getDropDownItem(selectedCountryCode)).toLowerCase()
    );
  const [containerWidth, setContainerWidth] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const adjustPanelWidth = () => {
    if (!isBrowser()) {
      return;
    }
    setContainerWidth(Number(document.querySelector('.dt-footer__top-holder')?.clientWidth));
  };

  const dropdownClass = classNames('dt-dropdown-region', {
    'shown-drop': isOpen,
  });

  useEffect(() => {
    adjustPanelWidth();
    window.addEventListener('resize', adjustPanelWidth);

    return () => window.removeEventListener('resize', adjustPanelWidth);
  }, []);

  return (
    <div className={dropdownClass} id={`dt-dropdown-region-${dropId}`}>
      <a
        className="btn"
        href="/"
        role="button"
        onClick={(event) => {
          event.preventDefault();
          setOpen(() => !isOpen);
        }}
      >
        <span className="dt-dropdown-region__opener">
          <span className="dt-dropdown-region__opener-img">
            <ReactCountryFlag
              countryCode={selectedCountry?.code}
              svg
              className="lazyload"
              loading="lazy"
              aria-label={selectedCountry?.name}
              alt={selectedCountry?.name}
            />
          </span>
          {selectedCountry?.name}
        </span>
        <IconCustom icon="next_arrow" />
      </a>
      {isOpen && (
        <div className="dt-dropdown-region__menu" style={{ width: containerWidth }}>
          <div className="dt-dropdown-region__menu-grid">
            {regions?.map(({ countries, title, region }) => (
              <DropdownRegionGroup
                key={title}
                title={title}
                region={region}
                countries={countries}
                toggleDropdown={setOpen}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownRegion;
