import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './Disclaimer.scss';
import { IDisclaimer } from './models';

const Disclaimer: FC<IDisclaimer> = ({ disclaimer }) => (
  <Container fluid className="disclaimer">
    <DangerouslySetInnerHtml className="disclaimer__text" html={disclaimer} />
  </Container>
);

export default Disclaimer;
