import React, { FC, useRef, useCallback } from 'react';
import classNames from 'classnames';
import useSwipe from 'hooks/useSwipe';
import useBodyRef from 'hooks/useBodyRef';
import loadable from '@loadable/component';
import IconCustom from 'components/common/IconCustom';
import Loadable from 'components/Loadable';
import DropdownLanguage from 'components/DropdownLanguage';

import { BODY_CLASS_NAME_MOBILE_MENU } from '../constants';
import { IHeaderSearch, TSearchButtonComponent, TSwipeBlockComponent } from './models';

import './HeaderSearch.scss';

const SearchButton: FC<TSearchButtonComponent> = ({ handleToggle, buttonSearch }) => (
  <button
    onClick={handleToggle}
    className="dt-header__search-btn"
    type="button"
    aria-label={buttonSearch}
  >
    <IconCustom icon="search_icon" />
  </button>
);

const SwipeBlock: FC<TSwipeBlockComponent> = ({ closeMenu, handleToggle, customFields }) => {
  const FocusTrap = loadable(() => import('focus-trap-react'));
  const AlgoliaSearchBox = loadable(() => import('./AlgoliaSearchBox'));

  const swipeBlock = useRef(null);
  useSwipe(swipeBlock, { onUp: closeMenu });

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
      }}
    >
      <div className="dt-header__search-internal" ref={swipeBlock}>
        <div className="dt-header__search-frame">
          <div className="dt-header__search-col">
            <AlgoliaSearchBox closeMenu={closeMenu} customFields={customFields} />
          </div>
        </div>
        <button onClick={handleToggle} className="dt-header__search-swipe" type="button">
          <IconCustom icon="next_arrow" />
          {customFields.swipeUpToClose ?? 'Swipe up to close'}
        </button>
        <button
          onClick={handleToggle}
          className="dt-header__search-btn  dt-header__search-btn-close"
          type="button"
          aria-label="Close"
        >
          <IconCustom icon="close_X_icon" />
        </button>
      </div>
    </FocusTrap>
  );
};

const HeaderSearch: FC<IHeaderSearch> = ({
  languageSelector: { title: langSelectorTitle, languages, currentLangCode, redirectBehavior },
  toggle,
  isActive,
  setMenuToggled,
  setMenuShown,
  customFields,
  buttonSearch,
}) => {
  const searchClasses = classNames('dt-header__search', {
    'dt-header__search--active': isActive,
  });

  const bodyTag = useBodyRef();

  const handleToggle = useCallback(() => {
    if (bodyTag) {
      isActive
        ? bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU)
        : bodyTag.classList.add(BODY_CLASS_NAME_MOBILE_MENU);
      toggle(!isActive);
      setMenuToggled(false);
      setMenuShown(false);
    }
  }, [isActive, bodyTag]);

  const closeMenu = useCallback(() => {
    toggle(false);

    setTimeout(() => {
      document.body.classList.remove(BODY_CLASS_NAME_MOBILE_MENU);
    }, 300);
  }, []);

  return (
    <div className={searchClasses}>
      <div className="dt-header__search-lang-wrapper">
        {languages ? (
          <div className="dt-header__language-switcher">
            <DropdownLanguage
              title={langSelectorTitle}
              langs={languages}
              currentLangCode={currentLangCode}
              redirectToHome={redirectBehavior}
            />
          </div>
        ) : null}
        <Loadable isActive={!isActive}>
          <SearchButton handleToggle={handleToggle} buttonSearch={buttonSearch} />
        </Loadable>
      </div>
      <div className="dt-header__search-holder">
        <Loadable isActive={isActive}>
          <SwipeBlock
            closeMenu={closeMenu}
            handleToggle={handleToggle}
            customFields={customFields}
          />
        </Loadable>
      </div>
    </div>
  );
};

export default HeaderSearch;
