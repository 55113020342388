import { LocalStorageData } from 'components/Header/HeaderSearch/AlgoliaSearchBox/models';

export const getStorageData = (key: string): LocalStorageData => {
  const data = localStorage.getItem(key);

  return data ? JSON.parse(data) : null;
};

export const setStorageData = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const removeStorageData = (key: string): void => localStorage.removeItem(key);

export default {
  getStorageData,
  setStorageData,
  removeStorageData,
};
