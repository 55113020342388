import React from 'react';
import { BVProvider, useBVInit } from 'hooks/useBV';
import { BazaarVoiceProps } from './models';

const BazaarVoice: React.FC<BazaarVoiceProps> = (props) => {
  const { url, children } = props;
  const context = React.useMemo(() => ({ url }), [url]);

  useBVInit(url);

  return <BVProvider value={context}>{children}</BVProvider>;
};

export default BazaarVoice;
