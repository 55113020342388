import React, { FC } from 'react';
import { Link } from 'gatsby';

import './NewsletterButtonCol.scss';

import IconCustom from 'components/common/IconCustom';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { TNewsletterButtonComponentProps } from './models';

const NewsletterButtonCol: FC<TNewsletterButtonComponentProps> = ({ bare, newsletterBanner }) => {
  const { regularTitle, titleText, footerButtonTitle, buttonText, buttonURL } = newsletterBanner;

  if (bare) {
    return (
      <div className="dt-newsletter-button-col">
        <Link
          to={extractUrlFromMultiPicker(buttonURL)}
          className="dt-newsletter-button-col__btn btn btn-outline-primary"
        >
          <span>{footerButtonTitle}</span>
          <IconCustom icon="arrow_shops" />
        </Link>
      </div>
    );
  }

  return (
    <div className="dt-newsletter-button-col">
      <strong className="dt-newsletter-button-col__title">{regularTitle}</strong>
      <Link
        to={extractUrlFromMultiPicker(buttonURL)}
        className="dt-newsletter-button-col__btn btn btn-outline-primary"
      >
        <span>
          <strong>{buttonText}</strong>
          {titleText}
        </span>
        <IconCustom icon="arrow_shops" />
      </Link>
    </div>
  );
};

export default NewsletterButtonCol;
