import { useEffect } from 'react';
import SwipeService from 'services/swipeService';

interface IHandlers {
  onUp?: Function;
  onRight?: Function;
  onLeft?: Function;
  onDown?: Function;
}

const useSwipe = (
  element: React.MutableRefObject<null>,
  { onUp, onRight, onLeft, onDown }: IHandlers
): void => {
  useEffect(() => {
    let swiper;
    setTimeout(() => {
      swiper = new SwipeService(element.current);
      if (swiper) {
        swiper.onUp(onUp);
        swiper.onRight(onRight);
        swiper.onLeft(onLeft);
        swiper.onDown(onDown);
      }
    }, 300);

    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, []);
};

export default useSwipe;
