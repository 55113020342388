import React, { FC } from 'react';
import cn from 'classnames';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import DropdownRegionItem from '../DropdownRegionItem';
import { IDropdownRegionGroup } from './models';
import './DropdownRegionGroup.scss';

const CLASS = 'dt-dropdown-region__group';
const DropdownRegionCol: FC<IDropdownRegionGroup> = (props) => {
  const { title, region, countries, toggleDropdown } = props;
  const rootClass = cn(CLASS, `${CLASS}--${region}`);

  return (
    <div className={rootClass}>
      <strong className={`${CLASS}-title`}>{title}</strong>
      {countries ? (
        <ul>
          {countries.map(({ code, linkURL, name, isLast }) => (
            <DropdownRegionItem
              key={name}
              code={code}
              name={name}
              url={extractUrlFromMultiPicker(linkURL)}
              isLast={isLast}
              toggleDropdown={toggleDropdown}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default DropdownRegionCol;
