import React, { FC } from 'react';
import useActiveLinks from 'hooks/useActiveLinks';
import SubMenuItem from './SubMenuItem';
import { ISubMenu } from './models';
import './SubMenu.scss';

const SubMenu: FC<ISubMenu> = ({ links, ariaLabel }) => {
  useActiveLinks();

  return (
    <nav className="dt-sub-menu" aria-label={ariaLabel}>
      <ul className="dt-sub-menu__list">
        {links.map((item) => (
          <SubMenuItem key={item.name} {...item} />
        ))}
      </ul>
    </nav>
  );
};
export default SubMenu;
