import React, { FC } from 'react';

import SocialNetworksLinks from './SocialNetworksLinks';
import './SocialNetworks.scss';

type TSocialNetworksComponent = {
  footerNavigation: PageContent.TFooterNavigation;
};
const SocialNetworks: FC<TSocialNetworksComponent> = ({ footerNavigation }) => {
  return (
    <div className="dt-social-networks">
      {footerNavigation?.footerMiddle?.title ? (
        <strong className="dt-social-networks__title">{footerNavigation.footerMiddle.title}</strong>
      ) : null}
      {footerNavigation?.footerMiddle?.links ? (
        <ul className="dt-social-networks__list">
          <SocialNetworksLinks links={footerNavigation.footerMiddle.links} />
        </ul>
      ) : null}
    </div>
  );
};

export default SocialNetworks;
