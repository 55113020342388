import { useEffect, useState } from 'react';

const useBodyRef = (): HTMLElement | null => {
  const [bodyRef, setBodyRef] = useState<null | HTMLElement>(null);
  useEffect(() => {
    setBodyRef(document.body);
  }, []);

  return bodyRef;
};

export default useBodyRef;
