import React, { FC } from 'react';
import FooterLinks from 'components/Footer/FooterLinks';
import './FooterBottomNav.scss';

const FooterBottomNav: FC<{ links: PageContent.TLink[] }> = ({ links }) => {
  return (
    <nav className="dt-footer__bottom-nav">
      <ul>
        <FooterLinks links={links} />
      </ul>
    </nav>
  );
};

export default FooterBottomNav;
