import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { WhatsAppWidgetProps, WhatsAppWidgetQuery } from './models';

import './WhatsAppWidget.scss';

const WhatsAppWidget = ({ langProp }: WhatsAppWidgetProps) => {
  const {
    allWhatsAppWidget: { nodes },
  }: WhatsAppWidgetQuery = useStaticQuery(graphql`
    {
      allWhatsAppWidget {
        nodes {
          lang
          isVisible
          text
          logoAltText
          link
          linkAriaLabel
        }
      }
    }
  `);
  const whatsAppWidget = nodes.find(({ lang }) => langProp === lang);

  if (!whatsAppWidget) {
    return null;
  }

  const { isVisible, text, logoAltText, link, linkAriaLabel } = whatsAppWidget;

  return isVisible && text && link ? (
    <div className="whatsapp-widget" data-testid="whatsapp-widget">
      <a
        className="whatsapp-widget__link"
        href={link}
        aria-label={linkAriaLabel || undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/whatsapp-logo.png" alt={logoAltText} />
        <DangerouslySetInnerHtml html={text} element="span" className="whatsapp-widget__text" />
      </a>
    </div>
  ) : null;
};

export default WhatsAppWidget;
