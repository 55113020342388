import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SocialNetworks from 'components/common/SocialNetworks';
import NewsletterButtonCol from 'components/common/NewsletterButtonCol';
import './FooterMiddle.scss';

type TFooterMiddleComponentProps = {
  footerNavigation: PageContent.TFooterNavigation;
  newsletterBanner: PageContent.TNewsletterBanner;
  newsletterSignUp: string;
};
const FooterMiddle: FC<TFooterMiddleComponentProps> = ({
  footerNavigation,
  newsletterBanner,
  newsletterSignUp,
}) => {
  return (
    <div className="dt-footer__middle">
      <Container fluid>
        <Row className="align-items-md-center">
          <Col sm={12} md={6}>
            <SocialNetworks footerNavigation={footerNavigation} />
          </Col>

          <Col sm={12} md={6}>
            {Number(newsletterSignUp) ? (
              <NewsletterButtonCol newsletterBanner={newsletterBanner} bare />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterMiddle;
