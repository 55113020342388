import React from 'react';
import { getBV, deferLoadBV } from 'utils/bazaarVoice';

export interface IUseBV {
  loadScript: () => void;
  showProductPicker: () => void;
}

export interface BVContextType {
  url?: string;
}

const BVContext = React.createContext<BVContextType>({});

export const BVProvider = BVContext.Provider;

export const useBVInit = (url?: string) => {
  React.useEffect(() => deferLoadBV(url), []);
};

export const useBV = (): IUseBV => {
  const { url } = React.useContext(BVContext);

  const showProductPicker = () => {
    if (url) getBV(url).then((bv) => bv?.ui('rr', 'submit_generic', {}));
  };
  const loadScript = () => deferLoadBV(url);

  return {
    loadScript,
    showProductPicker,
  };
};
