import React, { FC } from 'react';

import FooterTop from './FooterTop';
import FooterMiddle from './FooterMiddle';
import FooterBottom from './FooterBottom';
import './Footer.scss';

type TFooterComponent = {
  brandSettings: PageContent.TBrandSettings;
  regionDropDown: PageContent.TRegionDropDown;
  footerNavigation: PageContent.TFooterNavigation;
  newsletterBanner: PageContent.TNewsletterBanner;
  siteSettings: PageContent.TSiteSettings;
};
const Footer: FC<TFooterComponent> = ({
  brandSettings,
  regionDropDown,
  footerNavigation,
  newsletterBanner,
  siteSettings,
}) => {
  const hasFooterMiddleContent =
    footerNavigation?.footerMiddle?.title ||
    footerNavigation?.footerMiddle?.links ||
    newsletterBanner ||
    siteSettings?.newsletterSignUp !== '0';

  return (
    <footer className="dt-footer">
      <FooterTop
        footerNavigation={footerNavigation}
        brandSettings={brandSettings}
        regionDropDown={regionDropDown}
        titleRegions={siteSettings?.titleRegions || ''}
        disclaimer={siteSettings?.disclaimer}
      />
      {hasFooterMiddleContent ? (
        <FooterMiddle
          footerNavigation={footerNavigation}
          newsletterBanner={newsletterBanner}
          newsletterSignUp={siteSettings?.newsletterSignUp}
        />
      ) : null}
      <FooterBottom footerNavigation={footerNavigation} />
    </footer>
  );
};
export default Footer;
