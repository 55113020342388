import DettolSansExtraBold from '../../../../static/fonts/DettolSansWeb-ExtraBold.woff2';
import DettolSansRegular from '../../../../static/fonts/DettolSansWeb-Regular.woff2';
import DettolSansSemiBold from '../../../../static/fonts/DettolSansWeb-SemiBold.woff2';
import OpenSansRegular from '../../../../static/fonts/OpenSans-Regular.woff2';
import Icomoon from '../../../../static/fonts/icomoon.woff';

export const preloadFonts = [
  { href: DettolSansExtraBold, type: 'font/woff2' },
  { href: DettolSansRegular, type: 'font/woff2' },
  { href: DettolSansSemiBold, type: 'font/woff2' },
  { href: OpenSansRegular, type: 'font/woff2' },
  { href: Icomoon, type: 'font/woff2' },
];

// for eslint rule
export default preloadFonts;
