type TProduct = {
  name: string;
  link: string;
  image?: string;
  localImage?: Internal.LocalImage;
};

type TProductCategory = {
  name: string;
  link: string;
  localImage?: Internal.LocalImage;
};

export interface ICategoryWrapperProps {
  name: string;
  centered: boolean;
  children: React.ReactNode;
}

export interface IItemColumnProps {
  name: string;
  link: string;
  image: PageContent.TUmbracoImage;
}

export interface IPureHeaderNavDropdownMenuProps {
  categories: TProductCategory[];
  subCategories: TProductCategory[];
  products: TProduct[];
}

export enum PanelsBlockName {
  SUB_CATEGORIES = 'Sub Categories Nav Panel',
  PRODUCTS = 'Products Nav Panel',
}

export interface IHeaderNavDropdownMenuProps {
  panels: PageContent.TNavigationPanels[];
}
